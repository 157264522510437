import { useEffect, useMemo, useState } from 'react';

var state: { href: string; params: { [param: string]: string } } = {
  href: window.location.pathname,
  params: {}
};

var href_hooks: ((arg: string) => void)[] = [];

function attachHook(hook: (arg: string) => void) {
  if (href_hooks.indexOf(hook) === -1) href_hooks.push(hook);
}

function detachHook(hook: (arg: string) => void) {
  href_hooks = href_hooks.filter(test => test !== hook);
}

function triggerHooks() {
  href_hooks.forEach(hook => hook(state.href));
}

function updateHref(href: string) {
  if (href !== state.href) {
    state.href = href;
    triggerHooks();
  }
}

export function useHref() {
  const [value, setValue] = useState(state.href);
  useEffect(() => {
    attachHook(setValue);
    return () => {
      detachHook(setValue);
    };
  }, []);
  return [value, updateHref] as [string, (arg: string) => void];
}

export function useMatch(path: string, exact?: boolean) {
  // States
  const [href] = useHref();

  const splitRef = useMemo(() => href.split('?')[0].split('#')[0].split('/'), [
    href
  ]);

  const splitPath = useMemo(() => path.split('?')[0].split('#')[0].split('/'), [
    path
  ]);

  useEffect(() => {
    console.log('SplitRef: ', splitRef);
    console.log('SplitPath: ', splitPath);
  }, [href, path]);

  const isMatch = useMemo(() => {
    return splitPath.reduce(
      (prev, val, idx) =>
        prev && val.startsWith(':')
          ? splitRef[idx]
            ? true
            : false
          : val === splitRef[idx],
      true
    );
  }, [href, path, exact]);

  const params = useMemo(() => {
    return splitPath.reduce(
      (prev, val, idx) =>
        val.startsWith(':')
          ? { ...prev, [val.substr(1)]: splitRef[idx] }
          : prev,
      {}
    );
  }, [href, path]);

  return [isMatch, params] as [boolean, { [param: string]: string }];
}

// export class Routr {
//   private href: string;
//   private hooks: ((arg: string) => void)[];

//   // Constructor
//   constructor() {
//     this.href = location.pathname;

//     this.attachHook = this.attachHook.bind(this);
//     this.detachHook = this.detachHook.bind(this);
//     this.triggerHooks = this.triggerHooks.bind(this);
//     this.updatePath = this.updatePath.bind(this);
//     this.useHref = this.useHref.bind(this);
//     this.useMatch = this.useMatch.bind(this);
//   }

//   private attachHook(hook: Routr['hooks'][number]) {
//     if (this.hooks.indexOf(hook) === -1) this.hooks.push(hook);
//   }

//   private detachHook(hook: Routr['hooks'][number]) {
//     this.hooks = this.hooks.filter(test => test !== hook);
//   }

//   private triggerHooks() {
//     this.hooks.forEach(hook => hook(this.href));
//   }

//   private updatePath(path: string) {
//     if (path !== this.href) {
//       this.href = path;
//       this.triggerHooks();
//     }
//   }

//   public useHref() {
//     const [state, setState] = useState(this.href);
//     useEffect(() => {
//       this.attachHook(setState);
//       return () => {
//         this.detachHook(setState);
//       };
//     }, []);
//     return [state, this.updatePath] as [string, (arg: string) => void];
//   }

//   public useMatch(path: string, exact?: boolean) {
//     // States
//     const [href] = this.useHref();

//     const splitRef = useMemo(
//       () => href.split('?')[0].split('#')[0].split('/'),
//       [href]
//     );

//     const splitPath = useMemo(
//       () => path.split('?')[0].split('#')[0].split('/'),
//       [path]
//     );

//     const isMatch = useMemo(() => {
//       return splitPath.reduce(
//         (prev, val, idx) => prev && val === splitRef[idx],
//         true
//       );
//     }, [href, path, exact]);

//     const params = useMemo(() => {
//       return splitPath.reduce(
//         (prev, val, idx) =>
//           val.startsWith(':')
//             ? { ...prev, [val.substr(1)]: splitRef[idx] }
//             : prev,
//         {}
//       );
//     }, [href, path]);

//     return [isMatch, params] as [boolean, { [param: string]: string }];
//   }
// }

// export function useRoutr() {
//   return useMemo(() => new Routr(), []);
// }
